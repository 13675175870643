// Scroll to Top
btnToTop = document.getElementById("toTop")
window.onscroll = function() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    btnToTop.style.display = "block"
  } else {
    btnToTop.style.display = "none"
  }
}
btnToTop.addEventListener('click', function (event) {
  document.body.scrollTop = 0 // For Safari
  document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
})
// onLoad
window.onload = function() {
  // noF5
  document.onkeydown = function (e) {  
    return (e.key) !== 'F5'
  }
}